<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-06-06 10:19:06
 * @LastEditors: pj
 * @LastEditTime: 2023-06-06 11:27:20
-->
<template>
  <div class="lotteryRecord-list">
    <gl-title title="抽奖记录"></gl-title>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column
          type="index"
          :index="mIndexMethod"
          label="序号"
          align="center"
          min-width="30"
        ></el-table-column>

        <el-table-column prop="userName" label="用户名"></el-table-column>
        <el-table-column prop="awardName" label="奖项名称"></el-table-column>

        <el-table-column prop="name" label="收货人名称"></el-table-column>
        <el-table-column prop="phone" label="收货人电话"></el-table-column>
        <el-table-column
          prop="addressDetail"
          label="详细地址"
        ></el-table-column>

        <el-table-column
          prop="awardTime"
          label="中奖时间"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column prop="isEntity" label="奖品类型">
          <template slot-scope="scope">
            <span>{{ scope.row.isEntity == 1 ? '实体奖品' : '喜糖奖励' }}</span>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange"
      >
      </gl-pagination>
    </gl-card>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import ListMixin from '@/mixins/list.mixin.js'
import { getRecord } from '@/api/coox/lottery'

export default {
  name: 'lotteryRecord',
  // import引入的组件需要注入到对象中才能使用
  components: {},
  mixins: [ListMixin],
  props: {},
  data() {
    // 这里存放数据
    return {}
  },
  // 计算属性类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.mGetListFun = getRecord
    this.mGetList()
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped></style>
