/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-06-06 11:10:59
 * @LastEditors: pj
 * @LastEditTime: 2023-06-06 12:50:44
 */
import request from '@/plugins/request'

// 获得抽奖记录列表
export function getRecord (data) {
  return request({
    url: '/api/user/luckyDraw/getRecord',
    method: 'GET',
    params: data,
  })
}

//获取转盘奖项配置列表

export async function listLuckyDrawRotaryTable (data) {
  const result = await request({
    url: '/api/user/luckyDraw/listLuckyDrawRotaryTable',
    method: 'GET',
    params: data,
  })
  return { data: { rows: result.data, total: result.data.length } }
}


//根据ID获取配置
export function getLuckyDrawRotaryTableById (data) {
  const prams = { id: data }
  return request({
    url: '/api/user/luckyDraw/getLuckyDrawRotaryTableById',
    method: 'GET',
    params: prams,
  })
}


//修改 转盘奖项配置

export function updateLuckyDrawRotaryTable (data = {}) {
  return request({
    url: '/api/user/luckyDraw/updateLuckyDrawRotaryTable',
    method: 'POST',
    data: data,
  })
}


export default {
  getRecord,
  listLuckyDrawRotaryTable,
  getLuckyDrawRotaryTableById,
  updateLuckyDrawRotaryTable
}